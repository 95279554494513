import Vue from 'vue'
import VueRouter from 'vue-router'
import OptList from "../views/OptList";
import OptDetails from "../views/OptDetails";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: OptList
  },
  {
    path: '/opportunities',
    name: 'opportunities',
    component: OptList
  },
  {
    path: '/opportunities/:id',
    name: 'opportunity-details',
    component: OptDetails,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/erp',
  linkExactActiveClass: "active",
  routes
})

export default router
