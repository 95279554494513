<template>
  <div class="container"> 
    <div class="row mt-3">
      <div class="col">
        <h2>Angebot verknüpfen</h2>
        <div class="row mb-2">
          <div class="col-12">
            <v-select v-if="angebote" :options="angebote" placeholder="Bitte Angebot auswählen" label="Suchfeld" v-model="selAngebot"></v-select>
            <div v-if="!angebote">Keine Angebote vorhanden</div>
          </div>
        </div>
        
        <div class="row mb-2">
          <div class="col-6">
            <button class="btn btn-light" @click="$modal.hide('angebotVerknuepfen')">Abbrechen</button>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-primary" @click="speichern()">speichern</button>
          </div>
        </div>

      </div>  
    </div>
  </div>
</template>

<script>
import Api from "@/lib/api";
export default {
  name: "Angebotsverknuepfung",
  props: {
    id: String,
    oppId: String
  },
  data() {
    return {
      angebote:[],
      fehlerausgabe: null,
      selAngebot: null
    };
  },
  async created() {
    await this.loadAngebote();
  },
  methods: {
    async loadAngebote(){
      const params = new URLSearchParams();
      params.append('ID', this.id)
      params.append('oppID', this.oppId)
      const angebote = await Api.getKundenAngebote(params)
      console.log(angebote)
      this.angebote = angebote
    },
    async speichern(){
      const params = new URLSearchParams();
      params.append('ID', this.oppId)
      if(this.selAngebot){
        params.append('angebotID', this.selAngebot.ID)
      }
      try {
        await Api.saveAngebotsVerknuepfung(params);
        this.$emit("saved") // für das Modal (schließen)
      } catch (error) {
        this.fehlerausgabe = error.response.data.response
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/_colors.scss";
h2{
  color:$txt-dunkelblau;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: "Super_Grotesk_Condensed_Med";
}


</style>