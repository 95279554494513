<template>
  <div>
    <!--div v-if="loading" class="ms-4"> Wird geladen</div-->


    <div v-if="!loading">
      <div class="container">
        <div class="row">
          <div class="col">
            <h1>Opportunity-Verwaltung</h1>

            <div v-if="!eintraege">
              <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">Fehler</h4>
                Keine Einträge vorhanden
              </div>
            </div>

            <div v-if="eintraege && eintraege.error">
              <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">Fehler</h4>
                {{ eintraege.error }}
              </div>
            </div>

            <div class="card" v-if="eintraege && !eintraege.error">
              <div class="card-header">
                <form class="row" @submit.prevent="loadEintraege(1)">
                  <div class="col-1 pe-0">
                    <input type="text" class="form-control" placeholder="OPT-Nr." v-model="suchparameter.opt">
                  </div>
                  <div class="col-2  pe-0">
                    <input type="text" class="form-control" placeholder="Suchbegriff" title="Sucht im Oppti-Titel und im Feld Kontakte_Suchfeld" v-model="suchparameter.titel">
                  </div>
                  <div class="col-2  pe-0">
                    <v-select class="suchselect" :options="suchfelder.verant" label="Titel" placeholder="Verantwortlicher"
                      v-model="suchparameter.verant"></v-select>
                  </div>
                  <div class="col-2  pe-0">
                    <v-select class="suchselect" :options="suchfelder.kunden" label="Titel" placeholder="Kunde"
                      v-model="suchparameter.kunde"></v-select>
                  </div>
                  <div class="col-2  pe-0">
                    <v-select class="suchselect" :options="suchfelder.status" placeholder="Status" label="Titel"
                      v-model="suchparameter.status"></v-select>
                  </div>
                  <div class="col-3">
                    <div class="row">
                      <div class="col-5  pe-0">
                        <button class="btn btn-light suchbutton w-100" type="submit">anzeigen</button>
                      </div>
                      <div class="col-7">
                        <router-link class="btn btn-primary w-100"
                          :to="{ name: 'opportunity-details', params: { id: 'new' } }">
                          neue opportunity
                        </router-link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6 p-0 mb-3">
                    <span v-if="eintraege">{{ eintraege.total }} Opportunities gefunden</span>
                  </div>
                  <div class="col-6 p-0 mb-3 text-end">
                    <button class="btn btn-link blaetterknopf" v-if="eintraege.pagination.prevpage"
                      @click="loadEintraege(eintraege.pagination.prevpage)"><span
                        class="ifr if-chevron-left"></span></button>
                    <span v-for="page in eintraege.pagination.pages" :key="page">
                      <button @click="loadEintraege(page)"
                        :class="['btn', 'blaetterknopf', 'btn-link', { aktiv: page == eintraege.pagination.aktpage }]">{{ page }}</button>
                    </span>
                    <button class="btn btn-link blaetterknopf" v-if="eintraege.pagination.nextpage"
                      @click="loadEintraege(eintraege.pagination.nextpage)"><span
                        class="ifr if-chevron-right"></span></button>
                  </div>
                </div>

                <div class="tabelle">
                  <div class="tabellenkopf row">
                    <!--
                  <div class="col-2 spalte">OPT-Nr.<i class="ifr  if-triangle-sm-down cursor" @click="sort('Nummer','desc')"></i><i class="ifr if-triangle-sm-up cursor" @click="sort('Nummer','asc')"></i></div>
                  <div class="col-3 spalte">Titel<i class="ifr  if-triangle-sm-down cursor" @click="sort('Titel','desc')"></i><i class="ifr if-triangle-sm-up cursor" @click="sort('Titel','asc')"></i></div>
                  -->
                    <div class="col-2 spalte">
                      <span @click="sort('Nummer')">OPT-Nr.</span>
                      <i v-if="sortierung != null && sortierung.feld == 'Nummer' && sortierung.richtung == 'desc'"
                        class="ifr if-triangle-sm-down cursor"></i>
                      <i v-if="sortierung != null && sortierung.feld == 'Nummer' && sortierung.richtung == 'asc'"
                        class="ifr if-triangle-sm-up cursor"></i>
                    </div>

                    <div class="col-3 spalte">
                      <span @click="sort('Titel')">Titel</span>
                      <i v-if="sortierung != null && sortierung.feld == 'Titel' && sortierung.richtung == 'desc'"
                        class="ifr if-triangle-sm-down cursor"></i>
                      <i v-if="sortierung != null && sortierung.feld == 'Titel' && sortierung.richtung == 'asc'"
                        class="ifr if-triangle-sm-up cursor"></i>
                    </div>

                    <div class="col-3 spalte">Kunde</div>
                    <div class="col-4 spalte p-0">
                      <div class="row">
                        <div class="col-4 spalte">Status</div>
                        <div class="col-3 spalte">
                          <span @click="sort('Wert')">Wert</span>
                          <i v-if="sortierung != null && sortierung.feld == 'Wert' && sortierung.richtung == 'desc'"
                            class="ifr  if-triangle-sm-down cursor"></i>
                          <i v-if="sortierung != null && sortierung.feld == 'Wert' && sortierung.richtung == 'asc'"
                            class="ifr if-triangle-sm-up cursor"></i>
                        </div>
                        <div class="col-5 spalte">
                          <span @click="sort('Wiedervorlage')">Wiedervorlage</span>
                          <i class="ifr  if-triangle-sm-down cursor"></i>
                          <i class="ifr if-triangle-sm-up cursor"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tabellenkoerper" v-if="eintraege">
                    <div class="cursor row" v-for="eintrag in eintraege.Overview" :key="eintrag.ID"
                      @click="detailseite(eintrag.ID)">
                      <div class="col-2 spalte">{{ eintrag.Nummer }}</div>
                      <div class="col-3 spalte" :title="eintrag.Titel">{{ eintrag.Titel }}</div>
                      <div class="col-3 spalte"><span v-if="eintrag.Kunde">{{ eintrag.Kunde.Kundennummer }} - {{
                        eintrag.Kunde.Titel }}</span></div>
                      <div class="col-4 spalte p-0">
                        <div class="row">
                          <div class="col-4 spalte" v-if="!eintrag.Status"></div>
                          <div v-if="eintrag.Status"
                            :class="['col-4', 'spalte', 'status', 'status-' + eintrag.Status.ID]">{{ eintrag.Status.Titel
                            }}</div>
                          <div class="col-3 spalte text-end"><span v-if="eintrag.Wert">{{ eintrag.Wert }} €</span></div>
                          <div class="col-5 spalte text-end pe-4">{{ formatDate(eintrag.Wiedervorlage) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Api from "@/lib/api"

export default {
  name: "OptList",
  data() {
    return {
      loading: true,
      suchparameter: {
        opt: "",
        titel: "",
        verant: null,
        kunde: null,
        status: null,
      },
      eintraege: null,
      suchfelder: {
        status: [],
        kunden: [],
        verant: []
      },
      sortierung: null
    }
  },
  async created() {
    await this.loadFilter();

    if (this.$route.query.anzahl) this.suchparameter.anzahl = this.$route.query.anzahl
    if (this.$route.query.seite) this.suchparameter.seite = this.$route.query.seite
    if (this.$route.query.opp) this.suchparameter.opp = this.$route.query.opp
    if (this.$route.query.titel) this.suchparameter.titel = this.$route.query.titel
    if (this.$route.query.verant) {
      let verantw = null
      for (const verantwo of this.suchfelder.verant) {
        if (verantwo.ID == this.$route.query.verant) {
          verantw = verantwo
        }
      }
      []
      this.suchparameter.verant = verantw
    }
    if (this.$route.query.kunde) {
      let ku = null
      for (const kun of this.suchfelder.kunden) {
        if (kun.ID == this.$route.query.kunde) {
          ku = kun
        }
      }
      []
      this.suchparameter.kunde = ku
    }

    if (this.$route.query.status) {
      let stat = null
      for (const statu of this.suchfelder.status) {
        if (statu.ID == this.$route.query.status) {
          stat = statu
        }
      }
      []
      this.suchparameter.status = stat
    }
    if (this.$route.query.desc) {
      console.log("desc")
      this.sortierung = { richtung: "desc", feld: this.$route.query.desc }
    }
    if (this.$route.query.asc) {
      console.log("asc")
      this.sortierung = { richtung: "asc", feld: this.$route.query.asc }
    }
    await this.loadEintraege();
  },
  methods: {
    async loadEintraege(seite) {
      const suche = {}
      suche.anzahl = "10"
      if (seite) { suche.seite = seite + "" } else { suche.seite = "1" }
      if (this.suchparameter.opt) suche.opp = this.suchparameter.opt
      if (this.suchparameter.titel) suche.titel = this.suchparameter.titel
      if (this.suchparameter.verant) suche.verant = this.suchparameter.verant.ID
      if (this.suchparameter.kunde) suche.kunde = this.suchparameter.kunde.ID
      if (this.suchparameter.status) suche.status = this.suchparameter.status.ID
      if (this.sortierung) {
        if (this.sortierung.richtung == 'desc') {
          suche.desc = this.sortierung.feld
        }
        else {
          suche.asc = this.sortierung.feld
        }
      }
      const res = await Api.getOppList(suche);
      this.eintraege = res
      this.loading = false
      if (!mapsEqual(this.$route.query, suche)) {
        this.$router.push({ query: suche })
      }
    },
    async loadFilter() {
      const statusse = await Api.getStatus()
      const kunden = await Api.getKunden()
      const verantwo = await Api.getVerantwortliche()
      statusse.push('alle', '')
      this.suchfelder.status = statusse
      this.suchfelder.kunden = kunden
      this.suchfelder.verant = verantwo
    },
    formatDate(datum) {
      if (datum) {
        const dat = new Date(datum)
        return dat.toLocaleDateString('de-DE', { month: '2-digit', day: '2-digit', year: 'numeric' })
      } else {
        return null
      }

    },
    async sort(sortfeld) {

      if (this.sortierung != null && this.sortierung.feld == sortfeld) {
        if (this.sortierung.richtung == "desc") {
          this.sortierung.richtung = "asc"
        }
        else {
          this.sortierung.richtung = "desc"
        }
      }
      else {
        this.sortierung = { "feld": sortfeld, "richtung": "desc" }
      }
      this.loadEintraege(1)
    },
    detailseite(oppid) {
      this.$router.push({ name: "opportunity-details", params: { id: oppid } })
    }
  }
};

function mapsEqual(map1, map2) {
  let testVal, val

  if (Object.keys(map1).length !== Object.keys(map2).length) {
    return false;
  }

  for (let key of Object.keys(map1)) {
    val = map1[key]
    testVal = map2[key]
    // in cases of an undefined value, make sure the key actually exists on the object so there are no false positives
    if (testVal !== val) {
      return false
    }
  }

  return true
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/_colors.scss";

h1 {
  margin: 20px 0;
  color: $dunkelblau;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
}

a {
  color: inherit;
}

.card {
  border-radius: 10px;
  font-family: 'OpenSansCondensed';
  font-size: 1rem;
}

.card-header {
  background-color: #EEF5FA;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  padding: 20px;
}

.card-body {
  padding: 35px;
}

.tabellenkopf {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  overflow: hidden;
  margin-bottom: 2px;
}

.spalte {
  padding-top: 8px;
  padding-bottom: 7px;
  color: grey;
  font-family: „Open Sans“, sans-serif;
  font-weight: 300;
}

.tabellenkopf .spalte {
  background-color: #7CA0B9;
  color: #ffffff;
}

.tabellenkopf>.spalte:not(:last-child) {
  border-right: 2px solid #ffffff;
}

.tabellenkopf .spalte .spalte {
  border-left: 2px solid #ffffff;
}

.tabellenkoerper>.row:nth-child(odd) {
  margin-bottom: 2px;
  background-color: #F2F2F2;
  border-radius: 7px;
}

.status {
  font-weight: bold;
  font-size: 0.84rem;
}

.status-17742A08BA0 {
  /*Akquisephase*/
  color: $aqua;
}

.status-177429FF67A {
  /*Verhandlung*/
  color: $gelb;
}

.status-17742A0D9A5 {
  /*Konzeption*/
  color: $pink;
}

.status-17742A0B727 {
  /*Angebotsphase*/
  color: $lindgruen;
}

.status-17738C02EB1 {
  /*Neu erstellt*/
  color: red;
}

.cursor {
  cursor: pointer;
}</style>
