/* eslint-disable */
import Vue from 'vue'

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import 'jquery'

import Datepicker from 'vuejs-datepicker';
import {de} from 'vuejs-datepicker/dist/locale';

import dateTimePicker from 'vue-bootstrap-datetimepicker';
//import VanillaDateTimePicker from 'vue-vanilla-datetime-picker';
import VModal from 'vue-js-modal'

import "bootstrap/dist/js/bootstrap";
//import "bootstrap/js/dist/modal";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import App from './App.vue'

import router from './router'


import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import VueShortkey from "vue-shortkey";
 
Vue.component('datetime', Datetime);

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.config.productionTip = false

Vue.use(require('vue-moment'));
Vue.use(VueShortkey);

Vue.component('v-select', vSelect);
Vue.component('v-datepicker', Datepicker);
Vue.component('v-datetimepicker', dateTimePicker);
//Vue.component('date-time-picker', VanillaDateTimePicker);
Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);


Vue.use(VModal);

$.extend(true, dateTimePicker.defaults, {
  icons: {
    time: 'ifr if-trophy',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'ifr if-trophy',
    today: 'ifr if-trophy',
    clear: 'ifr if-trophy',
    close: 'ifr if-trophy'
  }
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
