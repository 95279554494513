import { render, staticRenderFns } from "./OptDetails.vue?vue&type=template&id=7326e9a0&scoped=true"
import script from "./OptDetails.vue?vue&type=script&lang=js"
export * from "./OptDetails.vue?vue&type=script&lang=js"
import style0 from "./OptDetails.vue?vue&type=style&index=0&id=7326e9a0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7326e9a0",
  null
  
)

export default component.exports