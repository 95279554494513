<template>
  <div class="container"> 
    <div class="row mt-3 mb-2" v-if="!loading">
      <div class="col">
        <h2 v-if="id == 'new'">Neues Angebot</h2>
        <h2 v-if="id != 'new'">Angebot {{angebot.Nummer}}</h2>
        <div class="row mb-2">
          <div class="col-12">
            <label for="titel" class="form-label">Titel</label>
            <input type="text" :class="['form-control', { 'is-invalid': ! ValideFelder.Titel }]" id="titel" v-model="angebot.Titel">
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <div class="col-12">
              <label for="anummer" class="form-label">Angebotsnummer</label>
              <input class="form-control" id="anummer" type="text" readonly v-model="angebot.Nummer" placeholder="wird erzeugt"/>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label">Angebotssumme</label>
            <div class="input-group">
              <input type="text" class="form-control" v-model="angebot.Angebotssumme">
              
              <!--select class="input-group-text" v-model="angebot.Angebotswaehrung">
                <option v-for="waehrung in selectfelder.waehrungen" :key="waehrung.ID">{{waehrung.Titel}}</option>
              </select-->
              <v-select class="waehrungsselect" :clearable="false" :options="selectfelder.waehrungen" label="Titel" v-model="angebot.Angebotswaehrung"/>
            </div>
          </div>
        </div>
<!--
        <div class="row mb-3">
          <div class="col-12">
            <label for="" class="form-label">Dokumente</label>
            <textarea class="form-control mb-3" rows="3" v-model="angebot.LinkSharepointDokument"></textarea>
            <textarea class="form-control" rows="3" v-model="angebot.LinkSharepointPDF"></textarea>
          </div>
        </div>
-->
        <div class="row mb-2">
          <div class="col-12">
            <label for="" class="form-label">Dokumente</label>
            <textarea  :class="['form-control', 'mb-2', { 'is-invalid': ! ValideFelder.linkObjekt }]" rows="3" v-for="link in angebot.linkObjekt" :key="link.ID" v-model="link.original"></textarea>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <button type="button" class="btn btn-success" @click="weitererLink()">+</button>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <label for="" class="form-label">Notizen</label>
            <textarea class="form-control" rows="3" v-model="angebot.Notizen"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <button class="btn btn-light" @click="$modal.hide('angebot')">Abbrechen</button>
          </div>
          <div class="col-8 text-end">
            <button class="btn btn-danger" @click="loeschen()"><i class="ifr if-trash"></i></button>
            <button type="button" class="btn btn-primary ms-2" @click="speichern()">Speichern</button>
          </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import Api from "@/lib/api";
export default {
  name: "Angebot_bearbeiten",
  props: {
    id: String,
    oppID: String,
    kundeID: String
  },
  data() {
    return {
      loading: true,
      angebot: null,
      fehlerausgabe: null,
      selectfelder: {
        waehrungen: []
      },
      valide: false,
      ValideFelder: {
        linkObjekt: true,
        Titel: true
      }
    };
  },
  async created() {
    await this.loadAngebot();
    await this.loadSelectFelder();
  },
  methods:{
    async loadAngebot() {
      if(this.id == "new"){
        this.angebot = {
          Titel: "",
          Angebotssumme: "",
          Angebotswaehrung: {ID:"15FA5E44C69",Titel:"EUR"},
          Notizen:"",
          ID: "new",
          linkObjekt: []            
        }
      }
      else{
        const res = await Api.getAngebot(this.id);
        this.angebot = res;
        if(this.angebot.linkObjekt == null)this.angebot.linkObjekt = []
      }
      this.weitererLink()
      this.loading = false
    },
    async loadSelectFelder(){
      this.selectfelder.waehrungen = await Api.getWaehrungen()
    },
    weitererLink(){
      this.angebot.linkObjekt.push({
        ID:"new",
        excel:"",
        original:"",
        ppoint:"",
        sharepoint:"",
        short:"",
        word:""
        })
    },
    validieren(){
      this.valide = true
      if(this.angebot.Titel == ""){
        this.valide = false
        this.ValideFelder.Titel = false
      } 
      else{
        this.ValideFelder.Titel = true
      }
      /*
      let keineLinks = true
      for (const link of this.angebot.linkObjekt) {
        if(link.original != ""){
          keineLinks = false
        }
      }

      if(keineLinks){
        this.valide = false
        this.ValideFelder.linkObjekt = false
      } 
      else{
        this.ValideFelder.linkObjekt = true
      }*/
    },
    async speichern(){
      this.validieren()
      if(this.valide){
        const params = new URLSearchParams();
        if(this.angebot.ID != "new"){params.append('ID', this.angebot.ID)} // bei neu wird keine ID mitgeschickt
        let waehrung = ""
        if(this.angebot.Angebotswaehrung){waehrung = this.angebot.Angebotswaehrung.ID}
        params.append('KundenID', this.kundeID)
        params.append('Angebotswaehrung', waehrung)
        params.append('Titel', this.angebot.Titel || "")
        params.append('opptieID', this.oppID || "")
        params.append('Angebotssumme', this.angebot.Angebotssumme || "")
        //params.append('LinkSharepointDokument', this.angebot.LinkSharepointDokument || "")
        //params.append('LinkSharepointPDF', this.angebot.LinkSharepointPDF || "")
        params.append('Notizen', this.angebot.Notizen || "")
        for (const link of this.angebot.linkObjekt) {
          if(link.ID == "new" && link.original != ""){
            params.append('Link', link.original)
          }
          else{
            params.append('Link_'+link.ID, link.original)
          }
        }
        console.log("in angebot speichern")
        if(this.angebot.ID != "new"){
          try {
            await Api.updateAngebot(params);
            this.$emit("saved") // für das Modal (schließen)
          } catch (error) {
            console.log(error)
            //this.fehlerausgabe = error.response.data.response
          }
        }
        else{
          try {
            await Api.createAngebot(params);
            this.$emit("saved") // für das Modal (schließen)
          } catch (error) {
            console.log(error)
            //this.fehlerausgabe = error.response.data.response
          }
        }
    }
    },
    async loeschen(){  // Angebotsverknüpfung im Opptie lösen
      const params = new URLSearchParams();
      params.append('ID', this.oppID)
      if(this.id != "new"){params.append('angebotID', this.angebot.ID)}
      try {
        await Api.deleteAngebot(params);
        await this.loadAngebot();
        await this.loadSelectFelder();
      } catch (error) {
        this.fehlerausgabe = error.response.data.response
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/_colors.scss";
h2{
  color:$txt-dunkelblau;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-family: "Super_Grotesk_Condensed_Med";
}


</style>