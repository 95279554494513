<template>
  <div class="container"> 
    <div class="row mt-3">
      <div class="col">
        <h2>Vertrag verknüpfen</h2>
        <div class="row mb-2">
          <div class="col-12">
            <v-select v-if="vertraege" :options="vertraege" placeholder="Bitte Vertrag auswählen" label="Suchfeld" v-model="selVertrag"></v-select>
            <div v-if="!vertraege">Keine Verträge vorhanden</div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <button class="btn btn-light" @click="$modal.hide('vertragVerknuepfen')">Abbrechen</button>
          </div>
          <div class="col-6 text-end">
            <button class="btn btn-primary" @click="speichern()">speichern</button>
          </div>
        </div>

      </div>  
    </div>
  </div>
</template>

<script>
import Api from "@/lib/api";
export default {
  name: "Vertragsverknuepfung",
  props: {
    id: String,
    oppId: String
  },
  data() {
    return {
      vertraege:[],
      fehlerausgabe: null,
      selVertrag: null
    };
  },
  async created() {
    await this.loadVertraege();
  },
  methods: {
    async loadVertraege(){
      const params = new URLSearchParams();
      params.append('ID', this.id)
      params.append('oppID', this.oppId)
      const vertraege = await Api.getKundenVertraege(params)
      this.vertraege = vertraege.Vertraege
    },
    async speichern(){
      const params = new URLSearchParams();
      params.append('ID', this.oppId)
      if(this.selVertrag){
        params.append('vertragID', this.selVertrag.ID)
      }
      try {
        await Api.savesaveVertragsVerknuepfungVertrag(params);
        this.$emit("saved") // für das Modal (schließen)
      } catch (error) {
        this.fehlerausgabe = error.response.data.response
      }
    }
  }
};
</script>

<style scoped>


</style>