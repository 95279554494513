<template>
  <div>
<div v-if="loading" class="ms-4"> Wird geladen</div>

<div v-if="!loading">

    <modal class="scrollbar" name="angebot" v-if="eintrag.Kunde" :clickToClose="false">
      <angebot @saved="savedAngebot()" :id="angebot" :oppID="eintrag.ID" :kundeID="eintrag.Kunde.ID"></angebot>
    </modal>

    <modal name="vertragVerknuepfen" v-if="eintrag.Kunde" :clickToClose="false">
      <vertragVerknuepfen @saved="savedVertragsVerknuepfung()" :oppId="eintrag.ID" :id="eintrag.Kunde.ID"></vertragVerknuepfen>
    </modal>

    <modal name="angebotVerknuepfen" v-if="eintrag.Kunde" :clickToClose="false">
      <angebotVerknuepfen  @saved="savedAngebotsVerknuepfung()" :oppId="eintrag.ID" :id="eintrag.Kunde.ID"></angebotVerknuepfen>
    </modal>

    <modal name="aktivitaet" v-if="eintrag.Kunde" :clickToClose="false" :scrollable="true" height="auto">
      <aktivitaet @saved="savedAktivitaet()" :id="aktivitaet.id" :typID="aktivitaet.typID" :kundeID="eintrag.Kunde.ID" :typTitel="aktivitaet.typTitel" :oppID="eintrag.ID"></aktivitaet>
    </modal>

    <modal name="gespeichert" class="gruenmodal">
      <div>Speicherung war erfolgreich.</div>
    </modal>


    <div class="container" v-if="!eintrag">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Fehler</h4>
            Keine Einträge vorhanden
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="eintrag && eintrag.error">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Fehler</h4>
            {{ eintrag.error }}
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="eintrag && !eintrag.error">

      <div class="row">
        <div class="col-12 mb-4">
          <!--<router-link class="btn btn-light" :to="{ name: 'opportunities' }">zurück</router-link>-->
          <button type="button" @click="$router.go(-1)" class="btn btn-light">zurück</button>
        </div>
      </div>

      <div class="row" v-if="eintrag">
        <div class="col-12">

          <div class="alert alert-success" role="alert" v-if="fehlerausgabe == 'ok'">
            Gespeichert!
          </div>
          <div class="alert alert-danger" role="alert" v-if="fehlerausgabe && fehlerausgabe != 'ok'">
            Es ist ein Fehler aufgetreten: {{fehlerausgabe}}
          </div>

          <div class="card">
            <span class="titel">
              {{ eintrag.Nummer }}<span v-if="eintrag.Titel"> - {{ eintrag.Titel }}</span>
              <v-select :clearable="false" :class="['statusauswahl', statusKlasse(eintrag)]" :options="selectfelder.status" label="Titel" v-model="eintrag.Status" />
            </span>

            <span class="subtitel">
              <span v-if="eintrag.Kunde">
                {{ eintrag.Kundentitel }}
                <a v-if="eintrag.Kunde" class="kontaktlink" target="_blank" :href="'https://crm.batix.com/www/projekte/kontakte/?load='+eintrag.Kunde.ID">zum Kontakt <i class="ifr if-external"></i></a>
              </span>
            </span>

            <div class="row">
              <div class="col-6">
                <div class="angabenform">
                  <h2>Allgemeine Angaben</h2>
                  <div class="row mb-2">
                    <div class="col-12">
                      <label class="form-label">Titel</label>
                      <input v-shortkey.once="['enter']" @shortkey="speichern" :class="['form-control', { 'is-invalid': ! ValideFelder.Titel }]" type="text" v-model="eintrag.Titel"/>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <label class="form-label">Kunde</label>
                      <v-select :class="['kundenauswahl', { 'is-invalid': ! ValideFelder.Kunde }]" :options="selectfelder.kunden" label="Titel" v-model="eintrag.Kunde" placeholder="" @input="getAnsprechpartner()"/>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Ansprechpartner</label>
                      <!--
                      <v-select :class="[{ 'is-invalid': ! ValideFelder.Ansprechpartner }]" :options="selectfelder.ansprechpartner" label="Titel" v-model="eintrag.Ansprechpartner" placeholder=""/>
                      -->
                      <v-select v-if="selectfelder.ansprechpartner != null && selectfelder.ansprechpartner.length > 0" :class="[{ 'is-invalid': ! ValideFelder.Ansprechpartner }]" :options="selectfelder.ansprechpartner" label="Titel" v-model="eintrag.Ansprechpartner" placeholder=""/>
                      <div v-if="selectfelder.ansprechpartner == null || selectfelder.ansprechpartner.length == 0">Keine Ansprechpartner vorhanden.</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <label class="form-label">Wiedervorlage-Datum</label>
                      <div class="input-group">
                        <!--<v-datepicker :language="de" mondayFirst="true" input-class="form-control" class="wiedervorlage" format="dd.MM.yyyy" v-model="eintrag.Wiedervorlage" name="uniquename" />-->
                        <!--<v-datepicker :language="de" input-class="form-control" class="wiedervorlage" format="dd.MM.yyyy" v-model="eintrag.Wiedervorlage" name="uniquename" />-->
                        <vue-ctk-date-time-picker v-model="eintrag.Wiedervorlage" :noLabel="true" inputSize="lg" :onlyDate="true" formatted="ll" class="wiedervorlage" locale="de" button-now-translation="Jetzt" label="" format="DD.MM.YYYY"/>
                        <span class="input-group-text"><i class="ifr if-calendar-3"></i></span>
                      </div>
                    </div>
                    <div class="col-6">
                      <label class="form-label">Wert</label>
                      <div :class="['input-group', { 'is-invalid': ! ValideFelder.Wert }]">
                        <input class="form-control" type="text" v-model="eintrag.Wert"/>
                        <span class="input-group-text">€</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <div class="mb-2">
                        <label class="form-label">Verantwortlicher</label>
                        <v-select :class="[{ 'is-invalid': ! ValideFelder.Verantwortlicher }]" :options="selectfelder.verant" label="Titel" v-model="eintrag.Verantwortlicher" placeholder=""/>
                      </div>
                    </div>
                    <div class="col-6 erstellt">
                      <span class="ersteller" v-if="eintrag.ID != 'new'">erstellt am {{ formatDate(eintrag.Erfassungsdatum) }} von {{eintrag.Erfasser.Titel}}</span>
                    </div>
                  </div>

                  <div class="blaukasten" v-if="eintrag.Kunde && eintrag.ID != 'new'">
                    <div class="row mb-2">
                      <div class="col-4">
                        <h3>Angebote</h3>
                      </div>
                      <div class="col-8 text-end">
                        <button class="btn btn-white" @click="angebotVerknuepfen()"><i class="ifr if-link"></i> Angebot verknüpfen</button>
                        <button class="btn btn-primary ms-2" @click="editAngebot('new')"><i class="ifr if-plus"></i> Neues Angebot</button>
                      </div>
                    </div>
                    <div class="row"  v-if="!eintrag.Angebote">
                      <div class="col-12">
                        Es sind noch keine Angebote verknüpft.
                      </div>
                    </div>
                    <div class="row"  v-if="eintrag.Angebote">
                      <div class="col-12">
                        <div class="item" v-for="angebot in eintrag.Angebote" :key="angebot.ID">
                          <div class="row mb-2">
                            <div class="col-3">
                              {{ angebot.Nummer }}
                            </div>
                            <div class="col-6">
                              {{angebot.Titel }}
                            </div>
                            <div class="col-3 text-end">
                              <i class="ifr if-scheck"></i> {{ angebot.Angebotssumme }}
                              <span v-if="angebot.Angebotswaehrung && angebot.Angebotswaehrung.ID == '15FA5E44C69'">€</span>
                              <span v-if="angebot.Angebotswaehrung && angebot.Angebotswaehrung.ID == '15FA5E47F15'">SF</span>
                            </div>
                          </div>

                          <div class="row" v-for="link in angebot.linkObjekt" :key="link.ID">
                            <div class="col-6 text-small">
                              <!--a :href="link.original" target="_blank">{{link.short}}</a-->
                              {{link.short || link.original}}
                            </div>
                            <div class="col-6 text-end">
                              <a v-if="link.word" class="btn btn-primary" :href="link.word" target="_blank" title="In Word öffnen"><i class="fa fa-file-word-o"></i></a>
                              <a v-if="link.excel" class="btn btn-success" :href="link.excel" target="_blank" title="In Excel öffnen"><i class="fa fa-file-excel-o"></i></a>
                              <a v-if="link.ppoint" class="btn btn-primary" :href="link.ppoint" target="_blank" title="In Power Point öffnen"><i class="fa fa-file-powerpoint-o"></i></a>
                              <a v-if="link.sharepoint && link.sharepoint.endsWith('.pdf')" data-name="sharepoint" class="btn btn-danger ms-2 px-4" title="PDF im Browser öffnen" :href="link.sharepoint" target="_blank"><i class="fa fa-file-pdf-o"></i></a>
                              <a v-else-if="link.sharepoint" data-name="sharepoint" class="btn btn-success ms-2" :href="link.sharepoint" title="Dokument downloaden" target="_blank"><i class="fa fa-download"></i></a>
                              <a v-if="link.original" class="btn btn-success ms-2"  data-name="original"  :href="link.original"  title="In Sharepoint öffnen" target="_blank"><i class="fa fa-cloud"></i></a>

                              <!--a v-if="!link.sharepoint && !link.ppoint && !link.word && !link.excel && link.original" class="btn btn-success ms-2" :href="link.original" target="_blank"><i class="ifr if-external"></i></a-->
                            </div>
                            <div class="col-12"><hr></div>
                          </div>

                          <div class="row mb-2">
                            <div class="col-12 text-end">
                              <button class="btn btn-danger" @click="delAngebotsverknuepfung(angebot.ID)" title="Verknüpfung zum Angebot löschen"><i class="ifr if-unchain"></i></button>
                              <button class="btn btn-light ms-2" @click="editAngebot(angebot.ID)"><i class="ifr if-pencil_short"></i> Angebot bearbeiten</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="blaukasten" v-if="eintrag.Kunde && eintrag.ID != 'new'">
                    <div class="row mb-2">
                      <div class="col-4">
                        <h3>Verträge</h3>
                      </div>
                      <div class="col-8 text-end">
                        <button class="btn btn-white" @click="vertragVerknuepfen()"><i class="ifr if-link"></i> Vertrag verknüpfen</button>
                        <a class="btn btn-primary ms-2" target="_blank" href="https://crm.batix.com/www/projekte/vertragserstellung/vertragsliste/"><i class="ifr if-plus"></i> Neuer Vertrag</a>
                      </div>
                    </div>
                    <div class="row"  v-if="!eintrag.Vertraege">
                      <div class="col-12">
                        Es sind noch keine Verträge verknüpft.
                      </div>
                    </div>
                    <div class="row"  v-if="eintrag.Vertraege">
                      <div class="col-12">
                        <div class="item" v-for="vertrag in eintrag.Vertraege" :key="vertrag.ID">
                          <div class="row">
                            <div class="col-2">{{ vertrag.Vertragsnummer }}</div>
                            <div class="col-7">{{ vertrag.Vertragskurztext }}</div>
                            <div class="col-3 text-end">xxxx €</div>
                          </div>
                          <div class="row mb-2 mt-2">
                            <div class="col-12 text-end">
                              <button class="btn btn-danger" @click="delVertragsverknuepfung(vertrag.ID)" title="Verknüpfung zum Vertrag löschen"><i class="ifr if-trash"></i></button>
                              <a class="btn btn-light ms-3" :href="'https://crm.batix.com/www/projekte/vertragserstellung/vertragsliste/edit.htm?vid='+vertrag.ID" target="_blank"><i class="ifr if-pencil_short"></i> Vertrag bearbeiten</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 text-end">
                      <button class="btn btn-danger" @click="loeschen()"><i class="ifr if-trash"></i></button>
                      <button class="btn btn-primary ms-3" v-shortkey.once="['ctrl','s']" @shortkey="speichern" @click="speichern()">speichern</button>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-6">
                <div class="aktivitaeten">
                  <h2>Aktivitäten</h2>
                  <div class="header">
                    <h3>Aktivität hinzufügen</h3>
                    <button class="btn btn-white me-2" @click="editAktivitaet('new','177A52488C8','Notiz')"><i class="ifr if-pencil_short"></i> Notiz</button>
                    <button class="btn btn-white me-2" @click="editAktivitaet('new','177A524DE49','Telefonat')"><i class="ifr if-mobile-2"></i> Telefonat</button>
                    <button class="btn btn-white me-2" @click="editAktivitaet('new','177A5250D69','E-Mail')"><i class="ifr if-mail-1"></i> E-Mail</button>
                    <button class="btn btn-white me-2" @click="editAktivitaet('new','177A525558A','Gespräch')"><i class="ifr if-speach_bubbles"></i> Gespräch</button>
                    <!--button class="btn btn-light-blue"><i class="ifr if-link"></i> Aktivität verknüpfen</button-->
                  </div>
                  <div class="inhalt" v-if="eintrag.Aktivitaeten">
                    <div class="item" v-for="akti in eintrag.Aktivitaeten" :key="akti.ID">
                      <div class="item-head">

                        <span v-if="akti.Typ.ID == '177A52488C8'"><i class="ifr if-pencil_short"></i> Notiz</span>
                        <span v-if="akti.Typ.ID == '177A524DE49'"><i class="ifr if-mobile-2"></i> Telefonat</span>
                        <span v-if="akti.Typ.ID == '177A5250D69'"><i class="ifr if-mail-1"></i> E-Mail</span>
                        <span v-if="akti.Typ.ID == '177A525558A'"><i class="ifr if-speach_bubbles"></i> Gespräch</span>

                        <div class="meta">{{formatDateTime(akti.Erfassungsdatum)}} Uhr von {{akti.Erfasser.Titel}}</div>
                        <button type="button" class="btn btn-light ecke dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                          <i class="ifr if-dots-three-vertical"></i>
                        </button>
                        <ul class="dropdown-menu bearbeitenauswahl dropdown-menu-end">
                          <li class="dropdown-item" @click="editAktivitaet(akti.ID,akti.Typ.ID,akti.Typ.Titel)">Bearbeiten</li>
                          <li class="dropdown-item" @click="delAktivitaet(akti.ID)">Löschen</li>
                        </ul>
                      </div>
                      <div class="item-body">

                        <div class="row">
                          <div class="col-1">
                            <i class="ifr if-calendar-3"></i>
                          </div>
                          <div class="col-11  ps-0">
                            am {{formatDateTime(akti.Zeitpunkt)}} Uhr
                          </div>
                        </div>
                        <div class="row" v-if="akti.Vertraege">
                          <div class="col-1">
                            <i class="ifr if-document"></i>
                          </div>
                          <div class="col-11  ps-0">
                            <span v-for="vert in akti.Vertraege" :key="vert.ID">{{vert.Titel}}<br></span>
                          </div>
                        </div>
                        <div class="row" v-if="akti.Teilnehmer">
                          <div class="col-1">
                            <i class="ifr if-customer-2"></i>
                          </div>
                          <div class="col-11  ps-0">
                            <span v-for="(teiln, index) in akti.Teilnehmer" :key="teiln.ID">{{teiln.Titel}}<span v-if="(index+1) < akti.Teilnehmer.length">, </span></span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-1">
                            <i class="ifr if-new-message"></i>
                          </div>
                          <div ref="klapptext" :class="['col-11', 'ps-0', 'pe-4', 'klapptext', 'text_'+akti.ID]">
                            <span class="pre-wrap">{{akti.Inhalt}}</span>
                            <br>
                            <span class="klappknopf" @click="klapp('text_'+akti.ID)"><i class="ifr if-chevron-sm-down"></i></span>
                          </div>
                        </div>
                        <div class="row" v-if="akti.Angebote">
                          <div class="col-1">
                            <i class="ifr if-attachment"></i>
                          </div>
                          <div class="col-11  ps-0">
                            <span v-for="angeb in akti.Angebote" :key="angeb.ID">{{angeb.Titel}}<br></span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Api from "@/lib/api";
import angebot from "@/components/Angebot";
import aktivitaet from "@/components/Aktivitaet";
import angebotVerknuepfen from "@/components/AngebotVerknuepfen";
import vertragVerknuepfen from "@/components/VertragVerknuepfen";
import {de} from 'vuejs-datepicker/dist/locale';

export default {
  name: "OptDetails",
  components: {
    angebot,
    angebotVerknuepfen,
    vertragVerknuepfen,
    aktivitaet
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      de: de,
      loading: true,
      user:{
        ID: "12826271BFA",
        Titel:"Frau Dörthe Kirstein"
      },
      eintrag: null,
      selectfelder: {
        status: [],
        kunden: [],
        verant: [],
        ansprechpartner: [],
        waehrungen: []
      },
      angebot: null, // für den Aufruf vom Angebot im Modal
      aktivitaet: [], // für den Aufruf von Aktivität im Modal
      vertraege:[],
      fehlerausgabe: null,
      valide: false,
      ValideFelder: {
        Titel: true,
        Kunde: true,
        Ansprechpartner: true,
        Wert: true,
        Verantwortlicher: true
      }
    };
  },
  async created() {
    await this.loadEintrag();
    await this.loadSelectFelder();
  },
  watch: {
    $route: "loadEintrag",
  },
  async updated() {
    $(".klapptext").each(function(  ) {
      if($( this ).height() <= 24){
        $(this).find(".klappknopf").remove()
      }
    });
  },
  methods: {
    async getAnsprechpartner(){
      const params = new URLSearchParams();
      if(this.eintrag.Kunde) params.append('ID', this.eintrag.Kunde.ID)
      if(this.id != "new") params.append('oppID', this.id)
      let  ansprechp = await Api.getAnsprechpartner(params)
      this.eintrag.Ansprechpartner = null
      if(ansprechp == null) ansprechp = []
      this.selectfelder.ansprechpartner = ansprechp
    },
    async loadEintrag() {
      if(this.id == "new"){
        this.eintrag = {
          Angebote: null,
          Ansprechpartner: null,
          Erfasser: null,
          Erfassungsdatum: null,
          ID: "new",
          Kunde: null,
          Kundentitel: null,
          Nummer: "Neu",
          Status: {ID:"17742A08BA0",Titel:"Akquisephase"},
          Titel: "",
          Verantwortlicher: await Api.getUser(),
          //Verantwortlicher: this.user,
          Vertraege: null,
          Wert: "0",
          Wiedervorlage: null
        }
      }
      else{
        const res = await Api.getOpp(this.id);
        res.Wert = this.formatPreis(res.Wert)
        if(res.Wiedervorlage != null){
          res.Wiedervorlage = this.$moment(String(res.Wiedervorlage)).format('DD.MM.YYYY')
        }
        else{
          res.Wiedervorlage = ""
        }
        this.eintrag = res;
      }
      this.loading = false
    },
    async loadSelectFelder(){
      const params = new URLSearchParams();
      if(this.id != "new") params.append('oppID', this.id)
      const statusse = await Api.getStatus(params)
      const kunden = await Api.getKunden(params)
      const verantwo = await Api.getVerantwortliche(params)
      const waehr = await Api.getWaehrungen()
      let ansprechp = []
      if(this.eintrag.Kunde){
        params.append('ID', this.eintrag.Kunde.ID)
        ansprechp = await Api.getAnsprechpartner(params)
      }
      this.selectfelder.status = statusse
      this.selectfelder.kunden = kunden
      this.selectfelder.verant = verantwo
      this.selectfelder.ansprechpartner = ansprechp
      this.selectfelder.waehrungen = waehr

    },
    formatDate(datum) {
      if(datum){
        const dat = new Date(datum)
        return dat.toLocaleDateString('de-DE', {month:'2-digit', day:'2-digit', year:'numeric'})
      } else{
        return null
      }
    },
    formatDateTime(datum) {
      if(datum){
        const dat = new Date(datum)
        return dat.toLocaleString('de-DE', {month:'2-digit', day:'2-digit',year:'numeric', hour: '2-digit', minute:'2-digit'})
      } else{
        return null
      }
    },
    formatPreis(preis) {
      //const options = {useGrouping:true,minimumFractionDigits:2,maximumFractionDigits:2}
      const options = {useGrouping:true}
      return new Intl.NumberFormat('de-DE',options).format(preis)
    },
    validieren(){
      this.valide = true
      const regex = new RegExp('^[0-9.]*[0-9]+(,[0-9]+)?$');

     // [0-9]{1,3}(\.[0-9]{3})(\.[0-9]{3}),

      if(this.eintrag.Titel == ""){
        this.valide = false
        this.ValideFelder.Titel = false
      }
      else{
        this.ValideFelder.Titel = true
      }
      if(this.eintrag.Kunde == null){
        this.valide = false
        this.ValideFelder.Kunde = false
      }
      else{
        this.ValideFelder.Kunde = true
      }
      /*
      if(this.eintrag.Ansprechpartner == null){
        this.valide = false
        this.ValideFelder.Ansprechpartner = false
      }
      else{
        this.ValideFelder.Ansprechpartner = true
      } */
      if(this.eintrag.Verantwortlicher == null){
        this.valide = false
        this.ValideFelder.Verantwortlicher = false
      }
      else{
        this.ValideFelder.Verantwortlicher = true
      }
      if(!regex.test(this.eintrag.Wert)){
        console.log("Wert entspricht nicht dem Regex: "+this.eintrag.Wert+" - "+regex)
        this.valide = false
        this.ValideFelder.Wert = false
      }
      else{
        this.ValideFelder.Wert = true
      }
    },
    async speichern(){
      this.validieren()
      if(this.valide){
        let wert = this.eintrag.Wert.replace(".","").replace(",",".")
        let wiedervorlage = ""
        let kunde = ""
        let ansprechp = ""
        let verantw = ""
        let status = ""
        if(this.eintrag.Wiedervorlage != ""){wiedervorlage = this.$moment(String(this.eintrag.Wiedervorlage),'DD.MM.YYYY').format('YYYY-MM-DD')}
        if(this.eintrag.Kunde){kunde = this.eintrag.Kunde.ID}
        if(this.eintrag.Ansprechpartner){ansprechp = this.eintrag.Ansprechpartner.ID}
        if(this.eintrag.Verantwortlicher){verantw = this.eintrag.Verantwortlicher.ID}
        if(this.eintrag.Status){status = this.eintrag.Status.ID}

        const params = new URLSearchParams();
        if(this.eintrag.ID != "new"){params.append('ID', this.eintrag.ID)} // bei neu wird keine ID mitgeschickt
        params.append('Titel', this.eintrag.Titel || "")
        params.append('Wiedervorlage', wiedervorlage)
        //params.append('Wiedervorlage', this.eintrag.Wiedervorlage)
        params.append('Wert', wert || "")
        params.append('Verantwortlicher', verantw)
        params.append('Status', status)
        params.append('Kunde', kunde)
        params.append('Ansprechpartner', ansprechp)

        if(this.eintrag.ID != "new"){
          try {
            const res = await Api.updateOpp(params);
            //wait Api.updateOpp(params);
            res.Wert = this.formatPreis(res.Wert)
            //res.Wiedervorlage = this.$moment(String(res.Wiedervorlage)).format('DD.MM.YYYY')
            if(res.Wiedervorlage != null){
              res.Wiedervorlage = this.$moment(String(res.Wiedervorlage)).format('DD.MM.YYYY')
            }
            else{
              res.Wiedervorlage = ""
            }
            this.eintrag = res
            this.$modal.show('gespeichert')
            //this.$router.push({ name: "opportunity-details", params: { id: this.eintrag.ID }})
          } catch (error) {
            this.fehlerausgabe = error.response.data.response
          }
        }
        else{
          try {
            const res = await Api.createOpp(params);
            this.eintrag = res
            this.$modal.show('gespeichert')
            this.$router.push({ name: "opportunity-details", params: { id: this.eintrag.ID }})

          } catch (error) {
            this.fehlerausgabe = error.response.data.response
          }
        }
      }
      else{
        console.log("invalide eingaben")
      }
    },
    async loeschen(){  // Opptie löschen
      if(confirm("Opptie wirklich löschen?")){
        if(this.eintrag.ID != "new"){
          await Api.deleteOpp(this.eintrag.ID);
        }
        this.$router.push({ name: "opportunities" })  // TODO Fehlerbehandlung???
      }
    },
    async delAngebotsverknuepfung(angebotID){  // Angebotsverknüpfung im Opptie lösen
      if (confirm('Verknüpfung wirklich löschen?')){
        const params = new URLSearchParams();
        params.append('ID', this.eintrag.ID)
        params.append('angebotID', angebotID)
        try {
          await Api.deleteAngebotsVerknuepfung(params);
          await this.loadEintrag();
          await this.loadSelectFelder();
        } catch (error) {
          this.fehlerausgabe = error.response.data.response
        }
      }
    },
    async delVertragsverknuepfung(vertragID){  // Vertragsverknüpfung im Opptie lösen
      if (confirm('Verknüpfung wirklich löschen?')){
        const params = new URLSearchParams();
        params.append('ID', this.eintrag.ID)
        params.append('vertragID', vertragID)
        try {
          await Api.deleteVertragsVerknuepfung(params);
          await this.loadEintrag();
          await this.loadSelectFelder();
        } catch (error) {
          this.fehlerausgabe = error.response.data.response
        }
      }
    },
    async delAktivitaet(aktID){
      if (confirm('Aktivität wirklich löschen?')){
        try {
          await Api.deleteAktivitaet(aktID);
          this.eintrag.Aktivitaeten = await Api.getAktivitaeten(this.eintrag.ID)
        }
        catch(error){
          console.log(error)
        }
      }
    },
    vertragVerknuepfen(){
      this.$modal.show('vertragVerknuepfen')
    },
    editAngebot(angebotsID){  // id oder "new"
      this.angebot = angebotsID
      this.$modal.show('angebot')
    },
    angebotVerknuepfen(){
      this.$modal.show('angebotVerknuepfen')
    },
    editAktivitaet(aktID,aktTypID,aktTypTitel){
      this.aktivitaet = {id:aktID,typID:aktTypID,typTitel:aktTypTitel}
      this.$modal.show('aktivitaet')
    },
    async savedAngebotsVerknuepfung(){
      this.$modal.hide('angebotVerknuepfen')
      await this.loadEintrag();
      await this.loadSelectFelder();
    },
    async savedVertragsVerknuepfung(){
      this.$modal.hide('vertragVerknuepfen')
      await this.loadEintrag();
      await this.loadSelectFelder();
    },
    async savedAngebot(){
      this.$modal.hide('angebot')
      await this.loadEintrag();
      await this.loadSelectFelder();
    },
    async savedAktivitaet(){
      this.$modal.hide('aktivitaet')
      await this.loadEintrag();
      await this.loadSelectFelder();
    },
    klapp(itemklasse){
      $('.'+itemklasse).toggleClass('auf')
    },
    statusKlasse(eintrag) {
      if (!eintrag || !eintrag.Status) {
        return null
      }
      return 'statusauswahl-' + eintrag.Status.ID
    }
  },
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/_colors.scss";
.titel {
  height: 25px;
	margin: 0 0 5px 0;
  color:$txt-dunkelblau;
  text-transform: uppercase;
  font-size:1.4rem;
  font-weight:bold;
  font-family: "Super_Grotesk_Condensed_Med";
}
h2{
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "Super_Grotesk_Condensed_Med";
  margin: 0 0 10px 0;
}
h3{
  color: #4E748E;
	font-size: 1.1rem;
  text-transform: uppercase;
  font-family: "Super_Grotesk_Condensed_Med";
}
.card {
  border-radius: 10px;
  font-family: "Open Sans";
  font-size: 1rem;
  padding: 25px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.25);
  border:none;
}
.angabenform {
  background-color: $bg-hellblau;
  border-radius: 6px;
  padding: 15px 20px;
}
.angabenform h2{
  color:$txt-dunkelblau;
}
.aktivitaeten{
  background-color: $bg-hellgruen;
  border-radius: 6px;
  padding: 15px 20px;
}
.aktivitaeten h2{
  color:$bg-dunkelhellgruen;
}
.aktivitaeten .header{
  background-color:$bg-mittelhellgruen;
  border-radius:4px;
  box-shadow:0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding:10px 20px;
  margin-bottom:15px;
}
.aktivitaeten .header .btn{
  background-color: #fff;
	border-radius: 4px;
  background-image:none;
  }
.aktivitaeten .header .btn:hover{
  background-color: #F5F7F1;
}
.aktivitaeten .header h3{
  font-size:1rem;
  color:$txt-dunkelgruen;
}
.aktivitaeten .item{
  margin-bottom: 10px;
	box-shadow: 0 1px 5px rgba(0,0,0,0.15);
}
.aktivitaeten .item-head{
  background-color:white;
  padding:10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  font-size:1.1rem;
  color:#4e748e;
  text-transform: uppercase;
  font-weight:bold;
  position:relative;
  font-family: Super_Grotesk_Condensed_Med;
	margin-left: -15px;
  /*box-shadow:0px 1px 10px 0px rgba(0, 0, 0, 0.1);*/
}
.aktivitaeten .item-head .meta{
  position:absolute;
  font-weight:normal;
  top:5px;
  font-family: "Open Sans", sans-serif;
	text-transform: none;
	font-size: 0.75rem;
	right: 50px;
}
.aktivitaeten .item-head .ecke{
  position:absolute;
  font-size:0.8rem;
  font-weight:normal;
  top:0;
  right:0;
  min-width:30px;
  min-height:30px;
  text-align: center;
  margin:0 0 0 5px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.25);
  border-radius: 0 0 0 6px;
  font-size: 1.5rem;
  padding: 3px 2px 0 2px;
}
.aktivitaeten .item-head .ecke i {
  margin: 0 0 0 5px;
}
.dropdown-toggle::after {
  border:none;
}
.aktivitaeten .item-body{
  background-color:white;
  margin-bottom:5px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color:#8c8c8c;
  padding: 10px 30px 10px 0;
  /*box-shadow:0px 1px 10px 0px rgba(0, 0, 0, 0.1);*/
}
.aktivitaeten .item-body i{
  color:$bg-dunkelhellgruen;
  font-size:1.1rem;
}
.aktivitaeten .item-body .row{
  margin-left:0;
  margin-bottom:10px;
}
.aktivitaeten .item-body a,.aktivitaeten .item-body a i{
  color:#4e748e;
}
.blaukasten{
  background-color:$bg-dunkelhellblau;
  border-radius: 4px;
  padding: 15px 20px;
  margin-bottom:15px;
}
.blaukasten .item{
  background-color:white;
  border-radius: 5px;
  padding: 2px 14px;
  margin: 10px 0 5px 0;
}
.blaukasten .item .row:first-of-type div:first-of-type{
  background-color:$bg-dunkelhellblau;
  padding: 5px 10px;
  border-top-left-radius: 6px;
  font-size:0.8rem;
  font-weight:600;
}
.blaukasten .item .row:first-of-type div:first-of-type + div{
  background-color:$bg-hellblau;
  padding: 5px 10px;
  font-weight:500;
  font-size:0.8rem;
  border-radius: 0 0 5px 0;
}
.erstellt{
  padding-top:39px;
}
.subtitel{
  font-family: "Open Sans", sans-serif;
	font-size: 0.9rem;
	color: #4E748E;
	margin: 0 0 23px 0;
}
.statusauswahl{
  background-color:rgb(185, 185, 185);
  color: inherit;
  text-transform: capitalize;
  height: 22px;
  border-radius: 11px;
  padding: 0 15px;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 1;
  display:inline-block;
  margin-left:15px;
  cursor:pointer;
}
.statusauswahl-17742A08BA0{ /*Akquisephase*/
  background-color:$status_aquisephase;
}
.statusauswahl-177429FF67A{ /*Verhandlung*/
  background-color:$status_verhandlung;
}
.statusauswahl-17742A0D9A5{ /*Konzeption*/
  background-color:$status_konzeption;
}
.statusauswahl-17742A0B727{ /*Angebotsphase*/
  background-color:$status_angebotsphase;
}
.statusauswahl-1781BA100DE{ /*Positivabschluss*/
  background-color:$status_positivabschluss;
}
.statusauswahl-1781BA123B2 { /*Negativabschluss*/
  background-color:$status_negativabschluss;
}
.ersteller{
  font-size: 0.9rem;
	color: #8C8C8C;
}
.kontaktlink{
  color:#8FC01A;
  font-size:0.7rem;
  margin-left:10px;
}
.klapptext{
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  position: relative;
}
.klapptext.auf{
  height: auto;
  display: block;
}
.klappknopf{
  position: absolute;
  top: 0;
  right: 0;
}
.klapptext.auf .ifr:before{
  content: '\e986';
}
.bearbeitenauswahl li{
  cursor:pointer;
}
.dropdown-item {
  text-align: right;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
}
</style>
