<template>
  <div class="container"> 
    <div class="row m-3" v-if="!loading">
      <div class="col">
        <h2 v-if="id == 'new'">Neue Aktivität</h2>
        <h2 v-if="id != 'new'">Aktivität bearbeiten</h2>
<!--
        <div class="row mb-3">
          <div class="col-12">
            <label for="angebot" class="form-label">Titel</label>
            <input class="form-control" type="text" v-model="aktivitaet.Titel"/>
          </div>
        </div>

        :config="{format: 'DD.MM.YYYY, hh:mm',useCurrent: true}"
-->
        <div class="row mb-2">
          <div class="col-5">
            <label for="typ" class="form-label">Aktivitätstyp</label>
            <v-select :clearable="false" :options="selectfelder.akttypen" label="Titel" placeholder="Bitte wählen" v-model="aktivitaet.Typ"/>
          </div>
          <div class="col-7">
            <label for="" class="form-label">Zeitpunkt d. Aktivität</label>
            <!--div :class="['input-group', { 'is-invalid': ! ValideFelder.Zeitpunkt }]">
              <v-datetimepicker input-class="form-control" :config="{format: 'DD.MM.YYYY, HH:mm',useCurrent: true}" class="wiedervorlage"  v-model="aktivitaet.Zeitpunkt"/>
              <span class="input-group-text"><i class="ifr if-calendar-3"></i></span>
            </div>
            <hr>
            <div :class="['input-group', { 'is-invalid': ! ValideFelder.Zeitpunkt }]">
              <datetime type="datetime" v-model="aktivitaet.Zeitpunkt" input-class="form-control" :minute-step="15"/>
              <span class="input-group-text"><i class="ifr if-calendar-3"></i></span>
            </div-->
            <div :class="['input-group', { 'is-invalid': ! ValideFelder.Zeitpunkt }]">
              <vue-ctk-date-time-picker v-model="aktivitaet.Zeitpunkt" class="form-control" locale="de" button-now-translation="Jetzt" label="" format="YYYY-MM-DD HH:mm:ss"/>
              <span class="input-group-text"><i class="ifr if-calendar-3"></i></span>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12">
            <label for="angebot" class="form-label">Angebot</label>
            <v-select v-if="selectfelder.angebote != null && selectfelder.angebote.length > 0" multiple :options="selectfelder.angebote" label="Suchfeld" placeholder="Bitte wählen" v-model="aktivitaet.Angebote"/>
            <div v-if="selectfelder.angebote == null || selectfelder.angebote.length == 0">Es gibt keine Angebote für den Kunden.</div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12">
            <label for="angebot" class="form-label">Gesprächspartner</label>
            <v-select multiple :options="selectfelder.gespraechspartner" label="Titel" placeholder="Bitte wählen" v-model="aktivitaet.Teilnehmer"/>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12">
            <label for="" class="form-label">Nachricht</label>
            <textarea :class="['form-control', { 'is-invalid': ! ValideFelder.Inhalt }]" rows="3" v-model="aktivitaet.Inhalt"></textarea>
          </div>
        </div>

        <!--div class="row mb-3">
          <div class="col-12">
            <label for="" class="form-label">Versendete Dokumente</label>
            <textarea class="form-control mb-3" rows="3" v-for="link in aktivitaet.linkObjekt" :key="link.ID" v-model="link.original"></textarea>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <button type="button" class="btn btn-success">+</button>
          </div>
        </div-->

        <hr>
        <span class="warnhinweis">Achtung: Status und Wiedervorlage ändern das Opptie</span>
        <div class="row mb-2">
          <div class="col-7">
            <label for="typ" class="form-label">Status</label>
            <v-select :options="selectfelder.status" placement="top" label="Titel" placeholder="Bitte wählen" v-model="selStatus"/>
            
          </div>
          <div class="col-5">
            <label for="" class="form-label">Wiedervorlage-Datum</label>
            <div class="input-group">
              <!--<v-datepicker calender-class="test" :language="de"  input-class="form-control" class="wiedervorlage" format="dd.MM.yyyy" v-model="selWiedervorlage"/>-->
              <vue-ctk-date-time-picker v-model="selWiedervorlage" :noLabel="true" inputSize="lg" :onlyDate="true" formatted="ll" class="wiedervorlage" locale="de" button-now-translation="Jetzt" label="" format="DD.MM.YYYY"/>
              <span class="input-group-text"><i class="ifr if-calendar-3"></i></span>
            </div>
            
          </div>
        </div>
        
        <div class="row">
          <div class="col-4">
            <button class="btn btn-light" @click="$modal.hide('aktivitaet')">Abbrechen</button>
            </div>
          <div class="col-8 text-end">
            <!--button class="btn btn-danger" @click="loeschen()"><i class="ifr if-trash"></i></button-->
            <button type="button" class="btn btn-primary ms-2" @click="speichern()">Speichern</button>
          </div>
        </div>

      </div>  
    </div>
  </div>
</template>getAktivitaetstypen

<script>


import Api from "@/lib/api";
import {de} from 'vuejs-datepicker/dist/locale';

export default {
  name: "Aktivitaet_bearbeiten",
  props: {
    id: String,
    oppID: String,
    typID: String,
    typTitel: String,
    kundeID: String
  },
  data() {
    return {
      de: de,
      loading: true,
      aktivitaet: null,
      fehlerausgabe: null,
      selectfelder: {
        akttypen: [],
        angebote: [],
        gespraechspartner: [],
        status:[]
      },
      selTyp: null,
      selStatus: null,
      selWiedervorlage: null,
      valide: false,
      ValideFelder: {
        Zeitpunkt: true,
        Inhalt: true
      }
    };
  },

  async created() {
    await this.loadAktivitaet();
    await this.loadSelectFelder();
  },
  methods: {
    async loadAktivitaet() {
      if(this.id == "new"){
        this.aktivitaet = {
          ID: this.id,
          //Titel: "",
          Typ: {ID:this.typID,Titel:this.typTitel},
          Teilnehmer: [],
          Angebote: [],
          OpportunityID: this.oppID,
          Zeitpunkt: "",
          Inhalt: ""
        }
      }
      else{
        const res = await Api.getAktivitaet(this.id);
        this.aktivitaet = res;
        this.aktivitaet.Opportunity = this.oppID
        if(res.Zeitpunkt != null){
          this.aktivitaet.Zeitpunkt = this.$moment(String(res.Zeitpunkt)).format('YYYY-MM-DD HH:mm:ss')
        }
        else{
          this.aktivitaet.Zeitpunkt = ""
        }
        
      }
      this.loading = false
    },
    async loadSelectFelder(){
      
      const params = new URLSearchParams();
      params.append('ID', this.kundeID)
      this.selectfelder.akttypen = await Api.getAktivitaetstypen()
      this.selectfelder.angebote = await Api.getKundenAngebote(params)
      const alleAnsprechpartner = await Api.getAnsprechpartner(params)
      for (const anspr of await Api.getVerantwortliche(params)) {
        alleAnsprechpartner.push(anspr)
      }
      this.selectfelder.gespraechspartner = alleAnsprechpartner
      this.selectfelder.status = await Api.getStatus(params)
    },
    validieren(){
      this.valide = true
      if(this.aktivitaet.Zeitpunkt == ""){
        this.valide = false
        this.ValideFelder.Zeitpunkt = false
      } 
      else{
        this.ValideFelder.Zeitpunkt = true
      }
      if(this.aktivitaet.Inhalt == ""){
        this.valide = false
        this.ValideFelder.Inhalt = false
      } 
      else{
        this.ValideFelder.Inhalt = true
      }
    },
    async speichern(){
      this.validieren()
      if(this.valide){
        const params = new URLSearchParams();
        if(this.aktivitaet.ID != "new"){params.append('ID', this.aktivitaet.ID)} // bei neu wird keine ID mitgeschickt
        //if(this.aktivitaet.Zeitpunkt){params.append('Zeitpunkt', this.$moment(String(this.aktivitaet.Zeitpunkt)).format('YYYY-MM-DD HH:mm:ss'))}
        if(this.aktivitaet.Zeitpunkt){params.append('Zeitpunkt', this.aktivitaet.Zeitpunkt)}
        if(this.aktivitaet.Teilnehmer){
          for (const teiln of this.aktivitaet.Teilnehmer) {
            params.append('Teilnehmer', teiln.ID)
          }
        }
        if(this.aktivitaet.Angebote.length > 0){
          for (const angeb of this.aktivitaet.Angebote) {
            params.append('Angebote', angeb.ID)
          }
        }
        else{
          params.append('Angebote', "-1")
        }
        params.append('OpportunityID', this.oppID)
        params.append('Inhalt', this.aktivitaet.Inhalt || "")
        params.append('Typ', this.aktivitaet.Typ.ID)
        if(this.selWiedervorlage){params.append('Wiedervorlage', this.$moment(String(this.selWiedervorlage),'DD.MM.YYYY').format('YYYY-MM-DD'))}
        if(this.selStatus){params.append('Status', this.selStatus.ID)}

        if(this.aktivitaet.ID != "new"){
          try {
            await Api.updateAktivitaet(params);
            this.$emit("saved") // für das Modal (schließen)
          } catch (error) {
            console.log(error)
            //this.fehlerausgabe = error.response.data.response
          }
        }
        else{
          try {
            await Api.createAktivitaet(params);
            this.$emit("saved") // für das Modal (schließen)
          } catch (error) {
            console.log(error)
            //this.fehlerausgabe = error.response.data.response
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/_colors.scss";

h2{
  color:$txt-dunkelblau;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "Super_Grotesk_Condensed_Med";
  margin: 0 0 10px 0;
}
.warnhinweis{
  font-size: 0.9rem;
  background: #ae183b;
  font-weight: 600;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 0 8px 0;
  display: inline-block;
}
</style>