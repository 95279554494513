import axios from "axios"

let config = {
  baseURL: "https://crm.batix.com/erp-api/",
  withCredentials: true
}

const requester = axios.create(config)

requester.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    window.location.href = `https://crm.batix.com/?_redirUrl=${window.location.href}`
  }
  return Promise.reject(error);
})

export default {

  // Liste Oppties
  async getOppList(params) {   // gibt nach ausgewählten Suchkriterien alle Oppties zurück, Anzeige der Liste
    const response = await requester.get("oppties", {
      params: params
    })
    return response.data
  },

  // einzelnes Opptie
  async getOpp(oppID) {   // gibt alle Angaben zu einem einzelnen Opptie zurück
    const response = await requester.get("opptie", {
      params: {
        ID: oppID
      }
    })
    return response.data
  },

  async createOpp(params) {    // legt ein Opptie mit den mitgeschickten Parametern an
    const response = await requester.post(`opptie`, params)
    return response.data
  },

  async updateOpp(params) {    // speichert Änderungen
    const response = await requester.put(`opptie`, params)
    return response.data
  },

  async deleteOpp(oppID) {
    const response = await requester.delete(`opptie`, {  // Opptie löschen (deaktivieren)
      params: {
        ID: oppID
      }
    })
    return response.data
  },

  // Listen für Auswahlfelder
  async getUser() {
    const response = await requester.get("aktivUser")
    return response.data
  },

  async getStatus(params) {
    const response = await requester.get("listeStatus", {
      params: params
    })
    return response.data.statusListe
  },

  async getWaehrungen() {
    const response = await requester.get("listeWaehrungen")
    return response.data.kundenListe
  },

  async getKunden(params) {
    const response = await requester.get("listeKunden", {
      params: params
    })
    return response.data.kundenListe
  },

  async getVerantwortliche(params) {
    const response = await requester.get("listeVerant", {
      params: params
    })
    return response.data.kontaktListe
  },

  async getAnsprechpartner(params) {
    const response = await requester.get("listeKundenAnsprechpartner", {
      params: params
    })
    return response.data.Ansprechpartner
  },

  async getKundenAngebote(params) {
    const response = await requester.get(`listeKundenAngebote`, {
      params: params
    })
    return response.data.Angebote
  },

  async getKundenVertraege(params) {
    const response = await requester.get(`listeKundenVertraege`, {
      params: params
    })
    return response.data
  },

  async getAktivitaetstypen() {
    const response = await requester.get(`listeAktivitaetsTypen`)
    return response.data.AktivitaetsTypen
  },

  // Angebote
  async getAngebot(angebotsID) {
    const response = await requester.get("angebot", {    // einzelnes Angebot nach ID holen
      params: {
        ID: angebotsID
      }
    })
    return response.data
  },

  async createAngebot(params) {    // legt ein neues Angebot mit den mitgeschickten Parametern an
    const response = await requester.post(`angebot`, params)
    return response.data
  },

  async updateAngebot(params) {    // Änderung des Angebots mit den mitgeschickten Parametern
    const response = await requester.put(`angebot`, params)
    return response.data
  },

  async deleteAngebot(angebotID) {
    const response = await requester.delete(`angebot`, {  // Angebot löschen (deaktivieren)
      params: {
        ID: angebotID
      }
    })
    return response.data
  },


  // Verknüpfungen mit Angebot
  async saveAngebotsVerknuepfung(params) {
    const response = await requester.put("opptieAngebot", params)    // verknüft ein Angebot mit dem Opptie
    return response.data
  },

  async deleteAngebotsVerknuepfung(params) {    // löscht eine Angebots-Verknüpfung im Opptie
    const response = await requester.delete("opptieAngebot", {
      params: params
    })
    return response.data
  },


  // Verknüpfungen mit Vertrag
  async saveVertragsVerknuepfung(params) {
    const response = await requester.put("opptieVertrag", params)    // verknüft einen Vertrag mit dem Opptie
    return response.data
  },

  async deleteVertragsVerknuepfung(params) {    // löscht eine Vertrag-Verknüpfung im Opptie
    const response = await requester.delete("opptieVertrag", {
      params: params
    })
    return response.data
  },

  // Aktivitäten
  async getAktivitaeten(oppID) {   // gibt alle Aktivietäten zu einem Opptie zurück
    const response = await requester.get("opptieAktivitaet", {
      params: {
        ID: oppID
      }
    })
    return response.data
  },

  async getAktivitaet(aktID) {
    const response = await requester.get("aktivitaet", {    // einzelnes Angebot nach ID holen
      params: {
        ID: aktID
      }
    })
    return response.data
  },

  async createAktivitaet(params) {    // legt eine neue Aktivität mit den mitgeschickten Parametern an
    const response = await requester.post(`aktivitaet`, params)
    return response.data
  },

  async updateAktivitaet(params) {    // Änderung der Aktivität mit den mitgeschickten Parametern
    const response = await requester.put(`aktivitaet`, params)
    return response.data
  },

  async deleteAktivitaet(aktID) {
    const response = await requester.delete("aktivitaet", {   // Aktivität löschen (deaktivieren)
      params: {
        ID: aktID
      }
    })
    return response.data
  }

}
